export default function Notfall() {
    //<a className="underline decoration-pink-600 hover:underline hover:decoration-black" href="mailto:rezepte-dr-bonin@t-online.de">rezepte-dr-bonin@t-online.de</a>
    return(
        <div className="container flex-1 overflow-y-auto mx-auto px-8 py-8 md:py-32">
            <div className="card bg-primary/50 shadow-2xl hover:shadow-2xl">
                <div className="card-body">
                    <div>
                        <h1 className="card-title">Bei Notfällen</h1>
                        <p>
                            Der <span className="font-bold">Ärztlicher Bereitschaftsdienst (ÄBD)</span> versieht für Sie den
                            <span className="font-bold"> Notdienst außerhalb der üblichen Sprechzeiten</span> -- d.h. an Wochenenden, feiertags,
                            werktags ab 18 Uhr, mittwochs schon ab 13 Uhr, freitags ab 14 Uhr, bis jeweils 7 Uhr am nächsten Werktag.
                        </p>
                        <p>
                            Geschultes Personal lotst Sie zu den <span className="font-bold">ÄBD-Zentralen</span> oder vermittelt nötige Hausbesuche,
                            mit denen Sie sonst in die Praxis gehen würden und deren Behandlung nicht bis zum nächsten Tag warten kann.

                        </p>
                        <div className="divider"></div>
                        <div className="">
                            Die <span className="font-bold">ÄBD-Leitstellen</span> sind unter der Telefonnummer <a className="font-bold hover:underline" href="tel:116117">116117</a><br/>
                            In <span className="font-bold">lebensbedrohlichen Notfällen wählen</span> Sie bitte die <span className="font-bold">Telefonnummer </span>
                            <a className="font-bold hover:underline " href="tel:112">112</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}