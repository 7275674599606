import React from "react";

export default function AboutUs() {
    return(
        <div className="container mx-auto px-4 max-sm:px-2 md:px-8 md:py-8 mb-8 xl:px-16">
            <div className="py-6">
                <div className="hero min-h-screen bg-gray-100 py-6 rounded-lg">
                    <div className="flex-col">
                        <div className="hero-content flex-col lg:flex-row-reverse px-7 md:px-10 py-6">
                            <img src="/Thomas_Wide.jpg" className="max-md:hidden inline max-w-sm rounded-lg shadow-2xl" alt="praxisteam"/>
                            <img src="/Thomas_Wide_small.jpg" className="md:hidden max-sm:max-h-40 max-w-xs  rounded-lg shadow-2xl" alt="praxisteam" />
                            <div className="lg:px-2 py-4 lg:mr-2">
                                <h1 className="text-xl xl:text-3xl font-bold">Über uns</h1>
                                <p className="py-4 text-justify text-sm lg:text-base">
                                    Als Facharzt für Allgemeinmedizin betreibe ich eine leistungsstarke,
                                    vielseitige Landarzt-Praxis. Unsere Stärke ist die Gesundheitsversorgung
                                    über alle Generationen hinweg, im besten Sinne einer family
                                    medicine, auf Ihre persönlichen Bedürfnisse eingehend.<br></br>
                                    Vor meiner Niederlassung war ich in Orthopädie, Neurologie, Chirurgie,
                                    Notfall- und Innerer Medizin tätig, sodann ab 1997 in Tropenmedizin und
                                    Public Health (Peru, Kongo, Malediven, Philippinen).
                                    2000 übernahm ich dann die hiesige Praxis. <br></br>
                                    Unser MFA-Team ist auf hohem Niveau
                                    für Sie tätig, in Prävention, Diagnostik und Therapie unterstützt es mich umfassend.
                                    Wir helfen Ihnen auf deutsch, englisch, spanisch, französisch, italienisch, tagalog und ilongo.
                                    Ich bin verheiratet und habe drei Kinder. Meine Hobbys sind Gärtnern, Malen, Radeln, Fremdsprachen
                                    lernen. Und ich bin sehr gern als Arzt für Sie da, im vollen Spektrum der Gesundheitsfürsorge.
                                    <br></br><br></br>
                                    Ihr Dr. med. Thomas Bonin
                                </p>
                            </div>
                        </div>
                        <div className="hero-content flex-col lg:flex-row-reverse px-7 md:px-10 py-6">
                            <img src="/Ivana.jpg" className="max-md:hidden inline max-w-sm rounded-lg shadow-2xl" alt="praxisteam"/>
                            <img src="/Ivana_small.jpg" className="md:hidden max-md:max-h-72 max-w-xs rounded-lg shadow-2xl" alt="praxisteam" />
                            <div className="lg:text-base lg:px-2 py-4 lg:mr-2">
                                <p className="py-4 text-justify text-base max-md:text-sm">
                                    Mein Name ist Ivana Šašić. Ich stehe Ihnen ab dem 01.11.2024 als neue ärztliche
                                    Weiterbildungs-Assistentin in der Praxis Dr. Bonin zur Seite. Ich lebe in Bad Orb,
                                    bin verheiratet und habe einen Sohn. Des Weiteren habe ich in Bad Orb
                                    bereits in den Rehazentren viele neurologische und internistische Erfahrungen gesammelt.
                                    Zuvor habe ich in meinem Heimatland Serbien sehr vielseitige Erfahrungen in der
                                    Medizin und Pflege gemacht.<br></br>
                                    Ich freue mich darauf, mich nun in Bieber allgemeinmedizinisch fortbilden zu können.
                                    <br></br><br></br>
                                    Ihre Assistenzärztin Ivana Šašić
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
