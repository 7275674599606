import React from 'react';


export default function Bewerbung() {

    return(
        <div className="container mx-auto px-8 py-8 mb-16">
            <p className="text-xl xl:text-3xl font-bold mb-6">Bewerbungsmöglichkeiten</p>
            <div className="mb-2">
                Alle Bewerbungen und Initiativ-Bewerbungen bitte unter: <a
                className="underline decoration-blue-800 text-blue-800 hover:underline hover:decoration-black hover:text-black"
                href="mailto:bewerbung@dr-bonin.de">
                    bewerbung@dr-bonin.de</a>
            </div>
            <div className="mx-auto">
                <Sections/>
            </div>
        </div>
    );
}


function Fachangestellte() {
    return(
        <div className="leading-loose px-2 lg:px-6">
            <p className="pb-4">
                ab sofort in Teilzeit gesucht für unser sehr kollegiales und eingespieltes Team.
            </p>
            <div className="text-xl font-bold"><h2>Profil:</h2></div>
            <p className="py-2">
                Erfolgreich abgeschlossene Ausbildung zur Medizinischen Fachangestellten.
                <br></br>
                Kollegialität, Engagement, Teamfähigkeit, Einfühlungsvermögen, Belastbarkeit und Flexibilität.
                <br></br>
                Sie besitzen eine selbständige Arbeitsweise und eine hohe Kommunikationsfähigkeit.
            </p>
            <div className="text-xl font-bold"><h2>Ihre Aufgaben:</h2></div>
            <p className="py-2">
                Aufnahme von Patient*innen im Empfangsbereich
                <br></br>
                Bearbeiten von Patientenakten am PC(Programm Quincy win), medizinische Dokumentation, allg. Bürotätigkeiten
                <br></br>
                Behandlungsassistenz
                <br></br>
                Durchführung von allg. Labortätigkeiten und medizin.-techn. Untersuchungen (Ruhe-EKG, LZ-RR, Spirometrie), Blutentnahmen
            </p>
            <div className="text-xl font-bold"><h2>Wir bieten Ihnen</h2></div>
            <div className="py-2">
                <ul className=" leading-loose list-disc list-inside">
                    <li className="leading-loose">
                        Dynamisches, zukunftsorientiertes Team
                    </li>
                    <li className="leading-loose">
                        Wertschätzendes und motiviertes Miteinander
                    </li>
                    <li className="leading-loose">
                        Externe und interne Fortbildungsmöglichkeiten
                    </li>
                </ul>
            </div>
        </div>
    );
}
/*
function Weiterbildung() {
    return (
        <div className="leading-loose px-2 lg:px-6">
            <p className="pb-4">
                ab sofort in Vollzeit oder mind. 50% gesucht.
            </p>
            <div className="text-xl font-bold"><h2>Profil:</h2></div>
            <p className="py-2">
                Erfolgreich beendete klinische WB.
                <br></br>
                Kollegialität, Engagement, Einfühlungsvermögen und Flexibilität.
                <br></br>
                Sie besitzen eine selbständige Arbeitsweise und eine hohe Kommunikationsfähigkeit.
            </p>
            <div className="text-xl font-bold"><h2>Wir bieten Ihnen</h2></div>
            <div className="py-2">
                <ul className=" leading-loose list-disc list-inside">
                    <li className="leading-loose">
                        Ein vielseitiges Klientel und Arbeitsspektrum (vgl. angebotene Leistungen)
                    </li>
                    <li className="leading-loose">
                        36 Monate Weiterbildungsbefugnis nach der WBO (2020)
                    </li>
                    <li className="leading-loose">
                        Wertschätzendes und motiviertes Miteinander
                    </li>
                </ul>
            </div>
        </div>
    );
}
*/
function Facharzt() {
    return (
        <div className="leading-loose px-2 lg:px-6">
            <p className="pb-4">
                ab sofort in Vollzeit oder zu zweit im Jobsharing mit je 50% gesucht.
            </p>
            <div className="text-xl font-bold"><h2>Profil:</h2></div>
            <p className="py-2">
                Sie haben erfolgreich Ihre deutsche Approbation beendet <span className="font-bold">oder</span> eine Berufserlaubnis.
                <br></br>
                Sie besitzen Engagement, Einfühlungsvermögen und schätzen Kollegialität.
            </p>
            <div className="text-xl font-bold"><h2>Aufgaben und Perspektiven:</h2></div>
            <p className="py-2">
                Eigenständiges Arbeiten, flache Hierarchien, wertschätzendes Miteinander
                <br></br>
                Leistungsspektrum vgl. angebotene Leistungen, ausbaufähig je nach Ihren Qualifikationen
                <br></br>
                Familienfreundliches Arbeitszeitmodell ohne Wochenend-, Feiertags- und Nachtdienste
                <br></br>
                Umzug in ein modernes Ärztezentrum in zentraler Lage folgt in 2025
                <br></br>
                Weitergabe der Praxis und des KV-Sitzes angestrebt
            </p>
            <div className="text-xl font-bold"><h2>Wir bieten Ihnen</h2></div>
            <div className="py-2">
                <ul className=" leading-loose list-disc list-inside">
                    <li className="leading-loose">
                        Sehr attraktive Arbeitsbedingungen
                    </li>
                    <li className="leading-loose">
                        Weit überdurchschnittliche Infrastruktur der Gemeinde (bis hin zu weiterführenden Schulen)
                    </li>
                    <li className="leading-loose">
                        Hoher Freizeitwert im schönen Spessart, sehr gute Work Life Balance
                    </li>
                </ul>
            </div>
        </div>
    );
}

/*
            <div className="py-6">
                <div className="group collapse collapse-plus rounded-lg shadow-2xl">
                    <input className="peer" type="checkbox" name="my-accordion-1"/>
                    <div className="collapse-title text-xl xl:text-2xl font-bold
                    bg-primary/50 text-primary-content peer-checked:bg-primary/20 peer-checked:text-secondary-content">
                        <div className="group-hover:translate-x-6 transform transition duration-500">
                            Med. Fachangestellte <span className="text-lg xl:text-xl">(w/m/d)</span
                        ></div>
                    </div>
                    <div className="collapse-content
                    bg-primary/50 text-primary-content peer-checked:bg-primary/20 peer-checked:text-secondary-content">
                        <Fachangestellte/>
                    </div>
                </div>
            </div>
 */

/*
    <div className="py-10">
                <div className="group collapse collapse-plus rounded-lg shadow-2xl">
                    <input className="peer" type="checkbox" name="my-accordion-1"/>
                    <div className="collapse-title text-xl xl:text-2xl font-bold
                    bg-primary/50 text-primary-content peer-checked:bg-primary/20 peer-checked:text-secondary-content">
                        <div className="group-hover:translate-x-6 transform transition duration-500">
                            Weiterbildungs-Assistent*in <span className="text-lg xl:text-xl">(w/m/d)</span>
                        </div>
                    </div>
                    <div className="collapse-content
                    bg-primary/50 text-primary-content peer-checked:bg-primary/20 peer-checked:text-secondary-content">
                        <Weiterbildung/>
                    </div>
                </div>
            </div>
 */
function Sections() {
    return(
        <div>
            <div className="py-8">

            </div>



            <div className="py-6">
                <div className="group collapse collapse-plus rounded-lg shadow-2xl">
                    <input className="peer" type="checkbox" name="my-accordion-1"/>
                    <div className="collapse-title text-xl xl:text-2xl font-bold
                    bg-primary/50 text-primary-content peer-checked:bg-primary/20 peer-checked:text-secondary-content">
                        <div className="group-hover:translate-x-6 transform transition duration-500">
                            Med. Fachangestellte <span className="text-lg xl:text-xl">(w/m/d)</span
                        ></div>
                    </div>
                    <div className="collapse-content
                    bg-primary/50 text-primary-content peer-checked:bg-primary/20 peer-checked:text-secondary-content">
                        <Fachangestellte/>
                    </div>
                </div>
            </div>

            <div className="py-10 ">
                <div className="group collapse collapse-plus  rounded-lg shadow-2xl">
                    <input className="peer" type="checkbox" name="my-accordion-1" />
                    <div className="collapse-title text-xl xl:text-2xl font-bold
                    bg-primary/50 text-primary-content peer-checked:bg-primary/20 peer-checked:text-secondary-content">
                        <div className="group-hover:translate-x-6 transform transition duration-500">
                            Facharzt/-ärztin Allgemeinmedizin <span className="text-lg xl:text-xl">(w/m/d)</span>
                        </div>
                    </div>
                    <div className="collapse-content
                    bg-primary/50 text-primary-content peer-checked:bg-primary/20 peer-checked:text-secondary-content">
                        <Facharzt/>
                    </div>
                </div>
            </div>
        </div>
    );
}